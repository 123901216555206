<template>
        <div class="contents">
            <div class="container-fluid">
                <div class="row mb-2 sticky" style="background-color: #f4f5f7 !important;">
                    <div class="col-lg-12">
                        <div class="contact-breadcrumb">
                            <div class="breadcrumb-main add-contact justify-content-sm-between p-3">
                                <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                    <div class="d-flex align-items-center add-contact__title justify-content-center">
                                        <h4 class="text-capitalize fw-500 breadcrumb-title">{{sname}}
                                            <ul class="atbd-breadcrumb nav">
                                                <li class="atbd-breadcrumb__item">
                                                    <a href="/">
                                                        <span class="la la-home"></span>
                                                    </a>
                                                    <span class="breadcrumb__seperator">
                                                        <span class="la la-slash"></span>
                                                    </span>
                                                </li>
                                                <li class="atbd-breadcrumb__item">
                                                    <router-link :to="url" style="color: #9299b8; cursor: pointer;">{{url.slice(9, url.length)}}</router-link>
                                                    <span class="breadcrumb__seperator">
                                                        <span class="la la-slash"></span>
                                                    </span>
                                                </li>
                                                <li class="atbd-breadcrumb__item">
                                                    <span class="bread-item">Topics</span>
                                                </li>
                                            </ul>
                                        </h4>
                                        <span class="sub-title ml-sm-25 pl-sm-25">{{cname}}</span>
                                    </div>
                                <div v-if="actionButton != 'purge'" class="d-flex align-items-center user-member__form my-sm-0 my-2 mr-sm-25" style="border-color: #e3e3e3 !important;">
                                    <span><i class="las la-search"></i></span>
                                    <input v-model="searchValue" class="form-control mr-sm-2 border-0 box-shadow-none" type="search" placeholder="Search by Topic" aria-label="Search">
                                    
                                    
                                </div>
                                <div class="d-flex align-items-center nowrap" >
                                    Sort By:
                                     <v-select v-model="sortSelection" label="name" :options="sortSelector" id="sortby" class="ml-2 form-control" placeholder="Select option"></v-select>
                                </div>
                                <div v-if="actionButton == 'purge'" class="filter-wrap">
                                    <v-select v-model="batchLabel" :options="batchList" placeholder="Select Batch"></v-select>
                                </div>
                                </div>
                                <div class="d-flex">
                                    <div class="d-flex mr-2" v-if="actionButton != 'purge'">
                                        <button type="button" class="btn btn-outline-secondary" style="margin-right:10px" @click="clear('addTopic')"  data-toggle="modal" data-target="#new-member" :disabled="disabled">Create Topic</button>
                                        <button type="button" class="btn btn-outline-secondary" @click="clear('addBulk')" data-toggle="modal" data-target="#new-member" :disabled="disabled" v-if="showToSuperAdminAndAbove">Add Bulk Topics</button>
                                    </div>


                                    <div class="d-flex" v-if="showToSuperAdminAndAbove">
                                        <button class="btn btn-primary" @click="purge(); batchLabel = ''" v-if="actionButton != 'purge' && batchList.length">Purge</button>
                                        <div class="d-flex" v-if="actionButton == 'purge'">
                                            <button type="button" class="btn btn-outline-secondary" style="margin-right:10px" @click="actionButton = ''; batchLabel = ''">Cancel</button>
                                        <button type="button" class="btn" :class="batchLabel ? 'btn-primary' : 'btn-outline-secondary'" style="margin-right:10px" @click="purge()" :disabled="!batchLabel">Purge {{batchLabel}}</button>
                                        </div>

                                    </div>

                                    <!-- <div class="action-btn" style="margin-right:15px">
                                        <button class="btn btn-default btn-squared btn-outline-secondary" @click="clear('addBulk')" data-toggle="modal" data-target="#new-member" :disabled="disabled">Add Bulk
                                                </button>
                                    </div>
                                    <div class="action-btn">
                                    <a @click="clear('addTopic')" href="#" class="btn px-15 btn-primary" data-toggle="modal" data-target="#new-member" :disabled="disabled">
                                            <i class="las la-plus fs-16"></i>Create New Topic</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tab Menu End -->
                <div class="projects-tab-content projects-tab-content--progress">
                    <div class="tab-content " id="ap-tabContent">
                        <div class="tab-pane fade show active" id="ap-overview" role="tabpanel" aria-labelledby="ap-overview-tab">
                            <div class="row">
                                <div v-if="isLoading && !$store.state.asyncLoader" class="col-12">
                                    <div class="spin-container text-center" style="margin-top: 20%;">
                                            <div class="atbd-spin-dots spin-lg">
                                                <span class="spin-dot badge-dot dot-primary"></span>
                                                <span class="spin-dot badge-dot dot-primary"></span>
                                                <span class="spin-dot badge-dot dot-primary"></span>
                                                <span class="spin-dot badge-dot dot-primary"></span>
                                            </div>
                                    </div>
                                </div>
                                <div v-if="!isLoading && filteredList.length < 1" class="col-12">
                                    <div class="card card-default card-md mb-4 no-content text-center">
                                       <div class="card-body">
                                            <img src="@/assets/img/custom/puppy-no-content.png" width="200" />
                                            <br />
                                            <h3>Oops</h3>
                                            <h6>No topics here...</h6>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="!isLoading && filteredList.length > 0" v-for="(topic, t) in filteredList" :key="t"  class="col-xl-3 mb-25 col-md-6">
                                    <div class="user-group radius-xl bg-white media-ui media-ui--early pt-30 pb-25 h-100">
                                        <!-- <span class="tlc" style="margin-right:5px; top:-18px" v-if="!topic.total_question && actionButton == 'purge'">
                                            <input type="checkbox" v-model="selection" name="selection" id="selection" :value="topic.tolid">
                                        </span> -->
                                        <span class="tlc"> Week {{topic.week}} - {{ordinal[topic.scheme]}} Term</span>
                                        <div class=" px-30 rel-pos">
                                            <router-link :to="{ name: preventSupportWithNeitherTeacherNorAgent, params: { tid: topic.tolid, tname: topic.name, clsId: clsId, sid: subId, url: url }}">
                                                <div class="media user-group-media d-flex justify-content-between">
                                                    
                                                    <div class="media-body d-flex align-items-center flex-wrap text-capitalize my-sm-0 my-n2">
                                                        <h6 class="mt-0  fw-500 mr-main" style="word-break: break-word">{{topic.name}}</h6>
                                                    </div>
                                                </div>
                                                <div class="user-group-people mt-15 text-capitalize">
                                                    <p :title="topic.note">{{(topic.note.substr(0,40) || 'No description') | titleCase}} {{topic.note.length>40 ? '...' : ''}}</p>
                                                
                                                </div>
                                                <div class="user-group-project" style="margin-bottom: -20px; margin-right:">
                                                    <div class="d-flex align-items-center user-group-progress-top">
                                                        <div>
                                                            <span class="color-light fs-12">Questions</span>
                                                            <p class="fs-14 fw-500 color-dark mb-0">{{topic.total_question}}</p>
                                                        </div>

                                                        <div class="mr-30">
                                                            <div class="">
                                                            <span class="color-light fs-12">Revision</span>
                                                            <p class="fs-14 fw-500 color-dark mb-0">{{topic.revision ? 'Yes' : 'No'}}</p>
                                                        </div>
                                                            <!-- <span class="color-light fs-12">Contributors</span>
                                                            <p class="fs-16 fw-500 color-success mb-0">{{topic.total_contributor}}</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                            <div class="tr-5">
                                                    <span class="circle-btn" @click.prevent="editTopic(topic)" data-toggle="modal" data-target="#new-member"><i class="la la-pencil-alt"></i></span>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="isLoading == false && subjectTopics.length > 0" class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-sm-end justify-content-star mt-1 mb-30">
                                        <nav class="atbd-page ">
                                            <ul class="atbd-pagination d-flex">
                                                <li class="atbd-pagination__item">
                                                    <a href="#" class="atbd-pagination__link pagination-control"><span class="la la-angle-left"></span></a>
                                                    <a href="#" class="atbd-pagination__link"><span class="page-number">1</span></a>
                                                    <a href="#" class="atbd-pagination__link active"><span class="page-number">2</span></a>
                                                    <a href="#" class="atbd-pagination__link"><span class="page-number">3</span></a>
                                                    <a href="#" class="atbd-pagination__link pagination-control"><span class="page-number">...</span></a>
                                                    <a href="#" class="atbd-pagination__link"><span class="page-number">12</span></a>
                                                    <a href="#" class="atbd-pagination__link pagination-control"><span class="la la-angle-right"></span></a>
                                                    <a href="#" class="atbd-pagination__option">
                                                    </a>
                                                </li>
                                                <li class="atbd-pagination__item">
                                                    <div class="paging-option">
                                                        <select name="page-number" class="page-selection">
                                                            <option value="20">20/page</option>
                                                            <option value="40">40/page</option>
                                                            <option value="60">60/page</option>
                                                        </select>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
                <!-- Modal -->
                <div class="modal fade new-member" id="new-member" data-backdrop="static" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content  radius-xl">
                            <div class="modal-header">
                                <h6 v-if="!editId" class="modal-title fw-500" id="staticBackdropLabel">Create Topic</h6>
                                <h6 v-else class="modal-title fw-500" id="staticBackdropLabel">Edit Topic</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" :disabled="disabled">
                                    <i class="las la-times" @click="resetTopicForm"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="new-member-modal">
                                    <div id="addTopic" v-if="actionButton == 'addTopic'">
                                        <form @submit.prevent="addTopic">
                                        <div class="form-group mb-20">
                                            <label class="mb-15">Topic name</label>
                                            <textarea v-model.trim="name" type="text" row="1" class="form-control" id="name47" placeholder="Enter here" style="height:75px"></textarea>
                                            <transition v-if="error && message != ''" name="slide-fade"><span class="text-danger">{{message}}</span></transition>
                                            
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-15">Week</label>
                                            <input type="number" min="1" max="99" class="form-control" placeholder="Enter Week"  v-model="week">
                                            <transition v-if="weekError" name="slide-fade"><span class="text-danger">{{weekError}}</span></transition>
                                        </div>
                                        <div class="form-group">
                                            <div class="d-flex align-items-center">
                                            <label class="ml-0 mb-0 mr-2">Revision</label>
                                            <div class="custom-control custom-switch switch-primary switch-md mt-1">
                                            <input type="checkbox" class="custom-control-input" id="revision" v-model="revision" :checked="revision">
                                            <label class="custom-control-label" for="revision"></label>
                                            </div>
                                            <div style="margin-bottom:30px">
                                                <span class="text-danger" v-if="schemeError">{{schemeError}}</span>
                                            </div>
                                        </div>
                                            
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-15">Term</label>
                                            <div style="display:flex; justify-content:space-between; padding:5px 15px;">
                                                <div class="radio-theme-default custom-radio">
                                                    <input class="radio" type="radio" v-model="scheme" name="scheme" value="1" id="radio-vl1">
                                                    <label for="radio-vl1">
                                                        <span class="radio-text">First Term</span>
                                                    </label>
                                                </div>
                                                <div class="radio-theme-default custom-radio">
                                                    <input class="radio" type="radio" v-model="scheme" name="scheme" value="2" id="radio-vl2">
                                                    <label for="radio-vl2">
                                                        <span class="radio-text">Second Term</span>
                                                    </label>
                                                </div>
                                                <div class="radio-theme-default custom-radio">
                                                    <input class="radio" type="radio" v-model="scheme" name="scheme" value="3" id="radio-vl3">
                                                    <label for="radio-vl3">
                                                        <span class="radio-text">Third Term</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div style="margin-bottom:30px">
                                                <span class="text-danger" v-if="schemeError">{{schemeError}}</span>
                                            </div>
                                            
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-15">Notes</label>
                                            <textarea v-model.trim="note" type="text" row="1" class="form-control" id="name47" placeholder="Enter here"></textarea>
                                        </div>
                                    </form>    
                                    <div class="button-group d-flex pt-25">
                                        <button v-if="!editId" class="btn btn-primary btn-default btn-squared text-capitalize float-right" @click="addTopic()" :disabled="disabled">add new topic
                                        </button>
                                        <button v-else class="btn btn-primary btn-default btn-squared text-capitalize float-right" @click="changeTopic()" :disabled="disabled">edit topic
                                        </button>
                                        <button id="myBtn" class="btn btn-light btn-default btn-squared fw-400 text-capitalize float-right b-light color-light" data-dismiss="modal" aria-label="Close" :disabled="disabled" @click="resetTopicForm">cancel
                                        </button>
                                    </div>
                                    </div>

                                    <div id="addBulk" v-if="actionButton == 'addBulk'">
                                        <div class="form-group mb-20">
                                            <label class="mb-15">Batch Number</label>
                                            <input type="text" class="form-control" v-model.trim="batchEntry.batch">
                                            <span class="error-text" v-if="!batchEntry.batch && batchSubmit">This field is required</span>
                                            
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-15">Select File(CSV)</label>
                                            <input type="file" class="form-control" style="padding:10px" @change="readFileAsJSON" id="csvFile" accept=".csv">
                                             <span v-if="csvError.error == 'NoFile' || csvError.error == 'NotCSV'" class="error-text">{{csvError.message}}</span>
                                        </div>
                                        <div class="form-control" style="height:220px">
                                            <label class="mb-15">Preview</label>
                                            <div class="t-header d-flex" >
                                                <span class="w-50px">S/N</span><span class="w-50px">Term</span><span class="flex-1">Topic</span>
                                            </div>
                                            <div class="body">
                                                <div v-for="(d, k) in csvDataClipped" :key="k" class="d-flex t-row">
                                                    <span class="w-50px">{{k+1}}</span>
                                                    <span class="w-50px">{{d.scheme}}</span>
                                                    <span class="flex-1">{{d.name.substr(0, 35)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                            <span v-if="fileHandler.data.length" style="font-weight:450;; font-size:12px">Total records: {{fileHandler.data.length}}</span>
                                            <span v-if="csvError.error == 'BadEntry' || csvError.error == 'BadHeader'" class="error-text">{{csvError.message}}</span>
                                        <div class="mt-3 d-flex">
                                            <button class="btn btn-primary" @click="addBulk" style="margin-right:15px">Add Bulk Topics </button>
                                            <button id="closeBulk" class="btn btn-light btn-default btn-squared fw-400 text-capitalize float-right b-light color-light" data-dismiss="modal" aria-label="Close" :disabled="disabled">cancel
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
        <ResponseModal ref="resModal" />
    <LoaderOverlay :active="loader.active" :fullpage="loader.fullpage" bgColor="#2a2a2a55" zIndex="99999"></LoaderOverlay>
        </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers.js";
import { apiClient } from "@/plugins/restclient.js";
import LoaderOverlay from "@/components/LoaderOverlay";
import ResponseModal from "@/components/ResponseModal.vue";
import { readAsText } from '@/utilities/file'
import Papa from 'papaparse'
export default {
  name: "classes-topics",
    components: {
        LoaderOverlay,
        ResponseModal
    },
  data() {
    return {
        isLoading: false,
        disabled: false,
            loader: {
                fullpage: true,
                active: false
            },
        name: "",
        url: '',
        editId: '',
        searchValue: '',
        subjectTopics: [],
        cls: [],
        subId: "",
        clsId: "",
        sname: '',
        cname: '',
        message: '',
        error: null,
        errors: false,
        errMsg: '',
        scheme: '',
        note:'',
        schemeError:'',
        ordinal:{
            1: "1st",
            2: "2nd",
            3: "3rd"
        },
        actionButton: '',
        fileHandler:{
            data: [],
        },
        csvError:{},
        batchEntry:{
            sulid: '',
            batch: '',
            topics:[]
        },
        batchSubmit: false,
        btnFilter: false,
        batchLabel: '',
        selection: [],
        week: 1,
        weekError: '',
        sortSelector:[
            {name: 'Week', value:'week'},
            {name: 'Term', value:'scheme'},
            {name: 'Name', value:'name'},
        ],
        sortSelection:{name: 'Term', value:'scheme'},
        revision: 0
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Topics",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  filters:{
    titleCase(value){

      if(!value) return ''

      let words = value.split(' ').map(i => {
        const w = i.trim()
        return  w ? w[0].toUpperCase() + w.substring(1).toLowerCase() : ''
      })

      return words.join(' ')

    }
  },
  computed: {
    ...authComputed,
    csvDataClipped(){
       return this.fileHandler.data.slice(0,5)
    },
    showToSuperAdminAndAbove(){
        return this.user?.role >= 64
    },
    preventSupportWithNeitherTeacherNorAgent(){
        return this.user?.role == 16 && !this.user?.idiosyncrasy.teacher && !this.user?.idiosyncrasy.agent ?  null : 'classes-topics-questions'
    },
    user() {
        return this.$store.getters.user
    },
    filteredList() {
        
        let topics = []
        if(!this.sortSelection){

            topics =  this.subjectTopics.filter(subjectTopic => {
                return subjectTopic.name.toLowerCase().includes(this.searchValue.toLowerCase()) && subjectTopic.status === 1;
            })
            topics = topics.sort((a,b) => a.scheme - b.scheme)
        }else{
            topics = [].concat(this.subjectTopics).sort((a,b) => a[this.sortSelection.value] - b[this.sortSelection.value])
        }
        
        return topics
    },
    batchList(){
        return Array.from(new Set(this.subjectTopics.map( i => i.balid).filter(i => i != '')))
    },
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  mounted() {
      window.jQuery("#select-tag").select2({
        placeholder: "Select option",
     });
    if (this.$route.params.sid) {
        this.url = this.$route.params.url;
        this.subId = this.$route.params.sid;
        this.clsId = this.$route.params.clsId;
        this.sname = this.$route.params.sname;
        this.cname = this.$route.params.cname;
        let obj = {
            subId: this.subId,
            clsId: this.clsId,
            sname: this.sname,
            cname: this.cname,
            url: this.url
        };
        localStorage.setItem("topics", JSON.stringify(obj));
        this.getTopics();
    } else {
        var jun = JSON.parse(localStorage.getItem("topics"));
        this.subId = jun.subId;
        this.clsId = jun.clsId;
        this.sname = jun.sname;
        this.cname = jun.cname;
        this.url = jun.url;
        this.getTopics();
    }
  },
  methods: {
      purge(){
          this.actionButton = 'purge'
          if(!this.batchLabel.length){
              return
          }
            this.isLoading = true
          apiClient.delete('/v1/office/frame/topic/bulk/purge',{
              data:{
                  batch: this.batchLabel
              }
          }).then((res) => {
              this.$refs.resModal.open({type:'success', message: res.data.message})
              this.getTopics()
              this.actionButton = ''
              this.batchLabel = ''
              this.isLoading = false
          }).catch((err) => {
              this.isLoading = false
              this.$refs.resModal.open({type:'error', message: err.response.data.message})
          })



      },
      readFileAsJSON(){
        
        readAsText('#csvFile', (result) => {
            if(result.error){
                this.csvError = result
                if(result.error == 'NoFile'){
                    this.fileHandler.data = []
                }else if(result.error == 'NotCSV'){
                    this.fileHandler.data = []
                }
                return
            }

            const jsonData = Papa.parse(result.data, {
                header: true,
                dynamicTyping: true
            })
            this.fileHandler = {
                ...jsonData
            }
           
        })
   
    
      },
    getTopics() {
        this.isLoading = true;
        var ts = Math.round(+new Date() / 1000);

      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };

      apiClient
        .get(`/v1/office/frame/topic/fetch/subject/${this.subId}`, {
          headers: headers,
        })
        .then((res) => {
            this.isLoading = false;
            this.errors = false;
          this.subjectTopics = res.data.data;
        })
        .catch((err) => {
            this.isLoading = false;
            this.errors = true;
            this.errMsg = err.response.data.message;
            
        });
    },
    clear(action) {
        this.batchEntry ={
            batch: '',
            sulid: '',
            topics: []
        }
        this.csvError = {
            error: '',
            message: ''
        }
        setTimeout(() => {
            document.getElementById("csvFile").value = ''
        }, 300);
     
        this.fileHandler.data = []
        this.batchSubmit = false
        this.name = '';
        this.editId = '';
        this.actionButton = action
    },
    resetTopicForm(){
        this.week = 1
        this.weekError = ''
        this.scheme = 1
        this.schemeError = ''
        this.note = ''
        this.name = ''
        this.message = ''
    },
    addBulk(){
        this.batchSubmit = true
       
        if(this.csvError.error || !this.batchEntry.batch ){
            return
        }
        
        if(!this.fileHandler.data.length){
            this.csvError = {
                error: 'NoFile',
                message: 'Please select a data file(csv)'
            }
            return
        }
        this.batchEntry = {
            ...this.batchEntry,
            sulid: this.subId,
            topics: [...this.fileHandler.data]
        }
        this.isLoading = true
        this.disabled = true
        this.loader.active = true
        apiClient.post('/v1/office/frame/topic/bulk/upload', this.batchEntry).then(res =>{
             setTimeout(() => {
                   document.getElementById("closeBulk").click(); 
                   document.getElementById('csvFile').value = ''
                }, 1000);
                this.fileHandler.data = []
            this.isLoading = false
            this.disabled = false
            this.loader.active = false
            this.getTopics();
            this.$refs.resModal.open({type:'success', message: res.data.message})
        }).catch(err => {
            this.isLoading = false
            this.disabled = false
            this.loader.active =false
            this.$refs.resModal.open({type:'error', message: err.response.data.message})
        })
    },
    addTopic() {
        if(this.scheme == ''){
            this.schemeError = 'Please select a term'
        }
        if(this.week == ''){
            this.weekError = 'Please enter week'
        }
        if(this.name == ''){
            this.error = true
            this.message = 'Topic name cannot be empty.';
        }
        if(this.scheme == '' || this.name == '' || this.week == ''){
            return
        }

        if (this.name.trim() != '') {
            this.isLoading = true;
            this.disabled = true;
            this.loader.active = true;
            var ts = Math.round(+new Date() / 1000);
            
            const headers = {
                "X-Timestamp": ts,
                "X-Ulid": `${this.$store.state.user.ulid}`,
            };

            const reqBody = {
                sulid: this.subId,
                name: this.name.trim(),
                week: parseInt(this.week),
                revision: this.revision ? 1 : 0,
                scheme: parseInt(this.scheme),
                note: this.note
            };

            apiClient.post('/v1/office/frame/topic/add', reqBody, { headers: headers }).then((res) => {
                setTimeout(() => {
                   document.getElementById("myBtn").click(); 
                }, 1000);
            this.isLoading = false;
            this.disabled = false;
            this.loader.active = false;
            this.error = null;
            this.errors = false;
            this.$refs.resModal.open({type:'success', message: res.data.message})
            this.getTopics();
            this.message = ''
            this.schemeError = ''
            }).catch(err => {
            this.isLoading = false;
            this.errors = true;
            this.$refs.resModal.open({type:'error', message: err.response.data.message})
            this.disabled = false;
            this.loader.active = false;
            this.message = ''
            this.schemeError = ''
            }); 
        } else {
            this.error = true;
        }
    },
    changeTopic() {
        this.isLoading = true;
        this.loader.active = true;
        var ts = Math.round(+new Date() / 1000);

      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };

      apiClient
        .patch(`/v1/office/frame/topic/change`, { tolid: this.editId, week: Number(this.week), scheme: Number(this.scheme), name: this.name, note: this.note } ,{
          headers: headers,
        })
        .then((res) => {
            setTimeout(() => {
                document.getElementById("myBtn").click(); 
            }, 1);
            this.$refs.resModal.open({type:'success', message: res.data.message})
            this.editId = '';
            this.name = '';
            this.note = '',
            this.scheme = ''
            this.loader.active = false;
            this.isLoading = false;
            this.errors = false;
            this.getTopics();
        })
        .catch((err) => {
            this.isLoading = false;
            this.errors = true;
            this.$refs.resModal.open({type:'error', message: err.response.data.message})
        });
    },
    editTopic(topic) {
        this.actionButton = 'addTopic'
        this.editId = topic.tolid;
        this.name = topic.name;
        
        this.scheme = topic.scheme
        this.note = topic.note
    },
    show(i) {
      var clssName = document.getElementById(i);
      clssName.className = "active";
    },
    prev() {
        this.$router.go(-1);
    }
  },
};
</script>

<style>
.h-75{
    height: 75px !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.bread-item:hover {
    color: #9299b8 !important;
}
.tlc{
    position: relative;
    font-size: 11px;
    left: 30px;
    top: -20px;
    color: red;
    font-weight: 500;
}
.sup{
    display: inline-block;
    position: relative;
    top: -25px;
}
.error-text{
        font-size: 13.5px;
    color: red;
}
.cl{
        border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}
.cr{
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}
.btn-filter{
    margin-top: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px !important;
   border: solid 1px #ff1312;
   color: #ff1312;
   cursor: pointer;
}
.btn-filter-checked{
    background: #ff1312;
    color: white;
}
.btn-filter *{
    margin: 0px;
    font-size: 12px;
}
.filter-wrap{
        background: rgb(255, 255, 255);
    border: 0px;
    border-radius: 50px;
    width: 350px;
    padding: 10px;
    margin: 0 15px;
}
.t-header{
    background: #f2f2f2; padding:7px; border-radius: 10px
}
.t-row{
    padding:3px; border-bottom: solid 1px #cacaca
}
.w-50px{
    width:50px;
}
.filter-wrap .vs__dropdown-toggle {
    height: 10px !important;
    /* border: none !important; */
    margin-top: 9px;
}
.filter-wrap input{
    font-size: 14px;
    color: gray;
}
.circle-btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;width: 30px;
    height: 30px;
    background: #fcddcc;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #d95006;
    font-size: 18px;
    cursor: pointer
}
.tr-5{
        position: absolute;
    top: -40px;
    right: 15px;
}
.form-control.v-select{
    padding: 0px;
    display: flex;
    align-items: center;
    min-width: 200px;
}
.form-control.v-select .vs__search::placeholder{
    color:#b9c0d8;
}
.vs__dropdown-toggle{
    /* border: 0px; */
    width:100%;
    padding-right: 5px;
    padding-left: 5px;
}
#sortby .vs__dropdown-toggle{
    border: 0px;
}
.vs__actions button{
     padding-bottom: 2px !important;
}
.nowrap{
    white-space: nowrap;
}
</style>
