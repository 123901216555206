const readAsText =  async (inputSelector, callback) => {

    let fr = new FileReader();
    let fileInput = document.querySelector(inputSelector).files[0]

    if(!fileInput){
      callback({ error: 'NoFile', message : 'Please pick a file'})
      return
    }
    // if(fileInput.type != 'text/csv'){
    //     callback({error: 'NotCSV', message: 'CSV expected but ' + fileInput.type + ' given'})
    //     return
    // }
    const name = fileInput.name.split('.')
    const ext = name[name.length - 1]
    if( ext != 'csv') {
        callback({error: 'NotCSV', message: 'CSV expected but unsupported file format given'})

        return
    }

    fr.onload = () => {
        callback({
            data:fr.result
            // filetype: fileInput.filetype
        })
    }
    fr.readAsText(fileInput)
}

const csvJSON = (csv, h='scheme,name,note') =>{
    const lines=csv.split("\n");

  var result = [];

  if(lines[0].trim().toLowerCase() != h.trim().toLowerCase()){
      return {error: 'BadHeader', message: 'Expected header format -  scheme,topic,note'}
  }
  var headers=lines[0].split(",").map(i => i.trim());

  for(var i=1;i<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(",");

      if(currentline[0].trim() == '' || currentline[1].trim() == ''){
          return {error:'BadEntry', message: `Incorrect entry on line: ${i} - term and topic is required`}
      }
      for(var j=0;j<headers.length;j++){
          if(j == 0 ) {
            obj[headers[j]] = Number(currentline[j].trim());
          }else{
             console.log(currentline[j].trim().replaceAll('"', '').replaceAll('\n',''))
              obj[headers[j]] = `${currentline[j].trim().replaceAll('"', '').replaceAll('\n','')}`
          }
      }

      result.push(obj);

  }

  return result;
}
export {
    readAsText,
    csvJSON
}